






























import { mapGetters, mapState } from 'vuex';
import { getMasterWalletTransactions } from '@/api/DashboardInfo';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { capitalization } from '@/helpers/formatString';
import type { AxiosError } from 'axios';
import type { WalletTransactionsResponce } from '@/api/schema';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

export default {
  name: 'TransactionHistory',
  data(): any {
    return {
      options: { ...defaultOptions },
      loading: false,
      transactionsData: [],
      total: 0
    };
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapGetters('Onboarding', ['operatorId']),
    headers(): { [key: string]: string }[] {
      return [
        {
          text: 'Wallet From',
          value: 'sender'
        },
        {
          text: 'Wallet To',
          value: 'receiver'
        },
        {
          text: `Amount (${this.tokenSymbol})`,
          value: 'amount'
        },
        {
          text: 'Transaction Type',
          value: 'type'
        }
      ];
    },
    currentPage(): number {
      return this.options.page - 1;
    }
  },
  watch: {
    options: {
      async handler(): Promise<void> {
        await this.updateTransactionsList();
      },
      deep: true
    },
    operatorId(newVal: number | null): void {
      if (newVal) this.options = { ...defaultOptions };
    }
  },
  methods: {
    back(): void {
      this.$router.back();
    },
    capitalization,
    updateTransactionsList(): void {
      if (!this.operatorId) return;

      this.loading = true;
      getMasterWalletTransactions({
        page: this.currentPage,
        size: this.options.itemsPerPage
      })
        .then((data: WalletTransactionsResponce): void => {
          const { totalElements, content } = data;
          this.total = totalElements;
          this.transactionsData = content;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading = false;
        });
    }
  }
};
